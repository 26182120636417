<template>
  <div :class="{ 'page-back': blankShow }">
    <NavBar style="background-color: #fff">
      <div slot="title">扫码支付订单</div>
    </NavBar>
    <div class="page-layout">
      <!-- 空白页的显示 -->
      <div class="blank-page" v-if="total <= 0">
        <div class="blank-img-box">
          <img
            src="~@/assets/common/no_evaluation_icon_is_available.png"
            alt=""
          />
        </div>
        <div style="color: #aaaaaa">没有任何扫码支付的订单哦!</div>
      </div>
      <!-- 有内容的显示 -->
      <div class="content-cord">
        <van-row type="flex" justify="space-between" class="row-title">
          <van-col>店铺名称</van-col>
          <van-col>支付单号</van-col>
          <van-col>支付金额</van-col>
        </van-row>
        <van-row
          class="list-text"
          type="flex"
          justify="space-between"
          v-for="(pay_info, pay_index) in list"
          :key="pay_index"
        >
          <!-- <van-col span="8">钜宏自营店铺</van-col>
          <van-col span="8">293849120</van-col>
          <van-col span="8">￥199.00</van-col> -->
          <van-col>{{ pay_info.shop_name }}</van-col>
          <van-col>
            <div>{{ pay_info.rs_sn }}</div>
            <div class="order-time">{{ pay_info.created_at }}</div>
          </van-col>
          <van-col class="price">￥{{ twoNum(pay_info.pay_money) }}</van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getShopOrderList } from "@/api/shop_order";
export default {
  name: "scan-code",
  components: {
    NavBar,
  },
  data() {
    return {
      blankShow: false, //将会使背景颜色一起更改为
      list: [],
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.getShopOrderList();
  },
  methods: {
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getShopOrderList() {
      const res = await getShopOrderList();
      console.log(res);
      if (res.code * 1 === 1) {
        ++this.page;
        this.list = this.list.concat(res.data.items);
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-back {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
}
.page-layout {
  padding: 70px 12px 12px 12px;
}
// 空白页样式
.blank-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -130%);
  font-size: 17px;
  .blank-img-box {
    width: 194px;
    img {
      display: block;
      width: 100%;
    }
  }
}
// 有内容的显示
.content-cord {
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  min-height: 80vh;
}
.van-row {
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}
.row-title {
  font-weight: 500;
  font-size: 17px;
}
.list-text {
  padding: 10px 0;
  font-size: 17px;
  .order-time {
    font-size: 13px;
    color: #aaaaaa;
  }
  .price {
    color: #e61f18;
  }
}
// .van-col-title {
//   text-align: center;
//   font-weight: 600;
// }
</style>
